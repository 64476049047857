import React, { useContext } from "react";
import NavCustom from "./NavCustom";
import NavAdmin from "./NavAdmin";
import AuthContext from "../Context/AuthContext";
import "../Styles/header.css";

const Header = () => {
  const { userInDB, user } = useContext(AuthContext);

  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
      <NavCustom />
      {(rolUserDB === "admin" || rolUserDB === "root") && user && <NavAdmin />}
    </div>
  );
};

export default Header;
