import React, { useEffect, useContext } from "react";
import AuthContext from "../Context/AuthContext";
import UsersCRUDContext from "../Context/UsersCRUDContext";
import ModalDeleteUser from "../Components/ModalDeleteUser";
import { Link, Navigate, useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Button } from "react-bootstrap";
import LoadingPage from "../Components/LoadingPage";
import "../Styles/crudProducts.css";

const UserList = () => {
  const { user, loading, userInDB } = useContext(AuthContext);
  const { usersDB, usersFirestoreDB } = useContext(UsersCRUDContext);
  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";

  const navigate = useNavigate();

  const handleDeleteUser = (u) => {
    fetch(
      `https://backend-inmobiliaria-estudio-q.onrender.com/deleteUser/${u.uid}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        res.json();
      })
      .catch((error) => console.log("error delete user: ", error));
    alert("Usuario borrado");
    window.location.reload();
  };

  useEffect(() => {
    document.title = "Lista de usuarios - Administrador";
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (rolUserDB !== "root" && rolUserDB !== "admin") {
    return <Navigate to="/my-account" />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <div className="container">
        <div className="d-flex align-items-center">
          <h1>Listado de Usuarios</h1>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-right`}>
                  <Popover.Header as="h3">Roles del sistema</Popover.Header>
                  <Popover.Body>
                    <strong>Rol ROOT</strong> tiene todos los permisos en el
                    sistema. Puede crear, modificar y eliminar los demas
                    usuarios. No pueder ser borrado, pero si puede modificar a
                    si mismo su nombre. <br />
                    <strong>Rol ADMIN</strong> tiene algunos permisos en el
                    sistema. Puede crear y modificar (solamente nombre) de los
                    demas usuarios, incluidos el mismo excepto el ROOT.
                    <br />
                    <strong>Rol USER</strong> es un usuario común, es decir no
                    tiene ni los permisos de ADMIN ni ROOT.
                  </Popover.Body>
                </Popover>
              }
            >
              <Button className="mx-2" variant="success">
                Ayuda
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="d-grid gap-2 div-btn-add-product">
          <Link to={`/crud-users`} className="mt-2 mb-2">
            Agregar Nuevo Usuario
          </Link>
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table className="table table-dark table-crud-products table-hover table-striped-columns">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Rol</th>
                <th>Nombre</th>
                <th>Ultima conexion</th>
                <th>Modificar</th>
                <th>Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {usersFirestoreDB.map((u, i) => (
                <tr
                  key={u.uid}
                  className={u.rol === "root" ? "table-primary" : ""}
                >
                  <td>{u.uid}</td>
                  <td>{u.email}</td>
                  <td>{u.rol}</td>
                  <td>
                    <div style={{ minWidth: "130px" }}>{u.fullName}</div>
                  </td>
                  <td>
                    <div style={{ width: "130px" }}>
                      {usersDB[i]?.metadata?.lastRefreshTime ||
                        usersDB[i]?.metadata?.lastSignInTime ||
                        usersDB[i]?.metadata?.creationTime}
                    </div>
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => navigate(`edit/${u.uid}`)}
                    >
                      Editar
                    </Button>
                  </td>
                  <td>
                    <ModalDeleteUser
                      disabled={u.uid === user.uid || rolUserDB === "admin"}
                      user={u}
                      handleDeleteUser={handleDeleteUser}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserList;
