import React, { useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import LoadingPage from "../Components/LoadingPage";
import "../Styles/loginRegister.css";

const Register = () => {
  const { user, loading, signUp, errorRegister, loginWithGoogle } =
    useContext(AuthContext);

  const handleSubmitRegister = async (email, pass, fullName) => {
    await signUp(email, pass, fullName);
  };

  const handleGoogleSingIn = async () => {
    await loginWithGoogle();
  };

  useEffect(() => {
    document.title = "Registrarse";
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (user) {
    return <Navigate to="/my-account" />;
  }

  return (
    <div className="container-login-user container-register-user">
      <div className="row" style={{ maxWidth: "1340px" }}>
        <div className="col-12 col-lg-4 title-login d-flex justify-content-lg-end">
          <p>Registrarse</p>
        </div>
        <div className="col">
          {errorRegister && (
            <h5
              style={{
                color: "red",
              }}
            >
              {errorRegister}
            </h5>
          )}
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              pass: "",
              checkPass: "",
            }}
            validate={(values) => {
              let errors = {};

              if (values.fullName.trim() === "") {
                errors.fullName = "Requerido";
              } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.fullName)) {
                errors.fullName = "Nombre invalido";
              }

              if (values.email.trim() === "") {
                errors.email = "Requerido";
              } else if (
                !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                  values.email
                )
              ) {
                errors.email = "Dirección de Email invalida";
              }

              if (values.pass.trim() === "") {
                errors.pass = "Requerido";
              } else if (/\s/.test(values.pass)) {
                errors.pass = "La contraseña no puede tener espacios";
              } else if (
                values.pass.split("").length < 6 ||
                values.pass.split("").length > 14
              ) {
                errors.pass = "Contraseña entre 6 y 14 caracteres";
              }

              if (values.checkPass.trim() === "") {
                errors.checkPass = "Requerido";
              } else if (values.pass !== values.checkPass) {
                errors.checkPass = "Las contraseñas no coinciden.";
              } else if (/\s/.test(values.checkPass)) {
                errors.checkPass =
                  "La confirmación de la contraseña tampoco puede tener espacios.";
              } else if (
                values.checkPass.split("").length < 6 ||
                values.checkPass.split("").length > 14
              ) {
                errors.checkPass =
                  "La confirmación de la contraseña debe tener entre 6 y 14 caracteres.";
              }

              return errors;
            }}
            onSubmit={(values, { resetForm }) => {
              handleSubmitRegister(
                values.email.trim(),
                values.pass,
                values.fullName.trim()
              );
            }}
          >
            {({
              handleSubmit,
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit} className="form-login-custom">
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Ingrese su nombre completo"
                    id="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={35}
                  />
                  {touched.fullName && errors.fullName && (
                    <Form.Text className="text-muted">
                      {errors.fullName}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Ingrese Email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={35}
                  />
                  {touched.email && errors.email && (
                    <Form.Text className="text-muted">{errors.email}</Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    id="pass"
                    placeholder="Ingrese una contraseña"
                    value={values.pass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={15}
                  />
                  {touched.pass && errors.pass && (
                    <Form.Text className="text-muted">{errors.pass}</Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    id="checkPass"
                    placeholder="Confirme la contraseña"
                    value={values.checkPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={15}
                  />
                  {touched.checkPass && errors.checkPass && (
                    <Form.Text className="text-muted">
                      {errors.checkPass}
                    </Form.Text>
                  )}
                </Form.Group>
                <div className="d-flex">
                  <button className="btn-login-custom" type="submit">
                    Registrarse
                  </button>
                  <div className="d-flex align-items-center mx-3 container-login-register-change">
                    <Link className="login-register-change" to="/login">
                      O Inicia sesión aquí
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="btn-google-option-login-register">
            <button onClick={handleGoogleSingIn}>
              O registrate con GOOGLE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
