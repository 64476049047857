import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slide from "../Components/Slide";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase";
import { getDocs, collection } from "firebase/firestore";
import ShareNetworks from "../Components/ShareNetworks";
import LoadingPage from "../Components/LoadingPage";
import "../Styles/inhabitTheCity.css";

const InhabitTheCity = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();

  const getProjects2 = async () => {
    const data = await getDocs(collection(db, "projects2"));
    setProjects(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getProjects2();
  }, []);

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div>
      <div
        {...props}
        className={
          "slick-prev prev-slick-arrow d-flex justify-content-center align-items-center" +
          (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
      >
        <FontAwesomeIcon icon={faCircleChevronLeft} />
      </div>
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div>
      <div
        {...props}
        className={
          "slick-next next-slick-arrow d-flex justify-content-center align-items-center" +
          (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
      >
        <FontAwesomeIcon icon={faCircleChevronRight} />
      </div>
    </div>
  );

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 7,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    infinite: projects[0]?.arrayImage.length > 6,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  };

  if (!projects[0]) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="title-our-projects d-flex justify-content-center">
        <p>Nuestros Proyectos</p>
      </div>
      <div className="static-img d-flex">
        <img src={projects[0]?.arrayImage[0]} alt="" className="img-fluid" />
      </div>
      <div
        style={{ maxWidth: "1340px" }}
        className="d-flex justify-content-around m-auto"
      >
        <div className="container-btn-urban-solution btn-info-card-alquiler btn-info-status-en-alquiler d-flex justify-content-center mt-3">
          <Link
            // to="urban-solutions"
            onClick={() => {
              navigate("/urban-solutions");
              navigate(0);
            }}
          >
            Soluciones Urbanas
          </Link>
        </div>
        <div className="container-btn-urban-solution btn-info-card-alquiler btn-info-status-en-alquiler d-flex justify-content-center mt-3">
          <Link
            onClick={() => {
              navigate("/inhabit-the-city");
              navigate(0);
            }}
          >
            Habitar la Ciudad
          </Link>
        </div>
      </div>
      <div className="d-flex justify-content-center align-center title-habitar">
        <p>Habitar la Ciudad</p>
      </div>
      <div className="mx-auto w-100" style={{ maxWidth: "1340px" }}>
        <div className="row m-0">
          <div className="col-12 col-md-6">
            <div className="slider-wrapper custom-slider-wrapper">
              <Slider
                {...settingsMain}
                asNavFor={nav2}
                ref={(slider) => setSlider1(slider)}
              >
                {projects[0]?.arrayImage.map((img_url) => (
                  <div
                    className="slick-slide d-flex justify-content-center"
                    key={img_url}
                  >
                    <img
                      className="slick-slide-image img-first-slider img-fluid"
                      src={`${img_url}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap thumbnail-slider-wrap-display-custom">
              <Slider
                {...settingsThumbs}
                asNavFor={nav1}
                infinite={projects[0]?.arrayImage.length > 5}
                ref={(slider) => setSlider2(slider)}
              >
                {projects[0]?.arrayImage.map((img_url) => (
                  <div className="slick-slide" key={img_url}>
                    <img className="slick-slide-image" src={`${img_url}`} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex align-items-center">
              <p className="product-name-page">{projects[0]?.projectName}</p>
              <ShareNetworks shareUrl={window.location.href} />
            </div>
            <div>
              <div className="row row-container-product-detail-page">
                <div className="col-5 p-0">
                  <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Terreno:</p>
                    <p className="p-size-area">{projects[0]?.land} m2</p>
                  </div>
                  <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Construidos:</p>
                    <p className="p-size-area">{projects[0]?.built} m2</p>
                  </div>
                </div>
                <div className="col-6 p-0">
                  <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Dormitorios:</p>
                    <p className="p-size-area">{projects[0]?.bedroom}</p>
                  </div>
                  <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Baños:</p>
                    <p className="p-size-area">{projects[0]?.bathroom}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-desc-product-detail">
              {projects[0]?.projectDescription}
            </div>
            <div className="d-flex">
              <div className="row w-100 row-price-and-btn-product-detail">
                <div className="p-0 mt-3 d-flex justify-content-start align-items-center container-btn-contact-product-detail">
                  <a
                    href={`https://wa.me/3816808535?text=Quisiera%20hacer%20una%20consulta%20sobre%20el%20siguiente%20inmueble:%20${projects[0]?.projectName}`}
                    target="_blank"
                    className="a-style-contactar"
                  >
                    Contactar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="thumbnail-slider-wrap thumbnail-slider-wrap-display-none-custom">
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={(slider) => setSlider2(slider)}
            >
              {projects[0]?.arrayImage.map((img_url) => (
                <div className="slick-slide" key={img_url}>
                  <img className="slick-slide-image" src={`${img_url}`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default InhabitTheCity;
