import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import ProductsCrudContext from "../Context/ProductsCRUDContext";
import AuthContext from "../Context/AuthContext";
import { Navigate } from "react-router-dom";
import LoadingPage from "../Components/LoadingPage";
import Loader from "../Components/Loader";
import "../Styles/crudProducts.css";

const CreateProducts = () => {
  const [loadingButton, setLoadingButton] = useState(false);

  const { addToDBproducts } = useContext(ProductsCrudContext);
  const { user, loading, userInDB } = useContext(AuthContext);

  const [arrayInput, setArrayInput] = useState(["url1"]);
  const [arrayInputObject, setArrayInputObject] = useState({ url1: "" });
  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";

  const handleAddInput = () => {
    let arrayAux = [...arrayInput];
    let lastElement = Number(arrayAux[arrayAux.length - 1].replace("url", ""));
    setArrayInput([...arrayInput, "url" + (lastElement + 1)]);
    setArrayInputObject({
      ...arrayInputObject,
      ["url" + (lastElement + 1)]: "",
    });
  };

  const handleDeleteInput = (index) => {
    let arrayAux = [...arrayInput];
    arrayAux.splice(index, 1);

    let arrayAuxInput = [];
    let arrayAuxInputObject = {};

    for (let i = 0; i < arrayAux.length; i++) {
      arrayAuxInput.push(arrayAux[i]);
      arrayAuxInputObject[arrayAux[i]] = "";
    }

    setArrayInput(arrayAuxInput);
    setArrayInputObject(arrayAuxInputObject);
  };

  const handleCreateProduct = (object) => {
    addToDBproducts(object);
  };

  const initialValuesFormik = {
    name: "",
    typeSelect: "",
    statusSelect: "",
    currencySelect: "",
    price: "",
    land: "",
    built: "",
    bedroom: "",
    bathroom: "",
    description: "",
    ...arrayInputObject,
  };

  useEffect(() => {
    document.title = "Alta del Producto - Administrador";
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (rolUserDB !== "root" && rolUserDB !== "admin") {
    return <Navigate to="/my-account" />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Agregar Nuevo Inmueble</h1>
            <Formik
              initialValues={initialValuesFormik}
              validate={(values) => {
                let errors = {};

                if (values.name.trim() === "") {
                  errors.name = "Requerido";
                }

                if (values.typeSelect.trim() === "") {
                  errors.typeSelect = "Seleccione una opción";
                }

                if (values.statusSelect.trim() === "") {
                  errors.statusSelect = "Seleccione una opción";
                }

                if (values.currencySelect.trim() === "") {
                  errors.currencySelect = "Seleccione una opción";
                }

                if (values.price.trim() === "") {
                  errors.price = "Requerido";
                } else if (!/^[+]?([.]\d+|\d+([.]\d+)?)$/.test(values.price)) {
                  errors.price = "Precio no valido";
                }

                if (values.land.trim() === "") {
                  errors.land = "Requerido";
                } else if (!/^[+]?([.]\d+|\d+([.]\d+)?)$/.test(values.land)) {
                  errors.land = "Terreno no valido";
                }

                if (values.built.trim() === "") {
                  errors.built = "Requerido";
                } else if (!/^[+]?([.]\d+|\d+([.]\d+)?)$/.test(values.built)) {
                  errors.built = "Area construida no valida";
                }

                if (values.bedroom.trim() === "") {
                  errors.bedroom = "Requerido";
                }

                if (values.bathroom.trim() === "") {
                  errors.bathroom = "Requerido";
                } else if (!/^(0|([1-9]\d*))$/.test(values.bathroom)) {
                  errors.bathroom = "Cantidad no valida";
                }

                if (values.description.trim() === "") {
                  errors.description = "Requerido";
                }

                return errors;
              }}
              onSubmit={(values, { resetForm }) => {
                setLoadingButton(true);
                const arrayAux = Object.keys(arrayInputObject);
                const valuesAux = Object.keys(values).splice(10);

                const newArrayFilterUrls = valuesAux.filter(
                  (x) => !arrayAux.includes(x)
                );

                for (let i = 0; i < newArrayFilterUrls.length; i++) {
                  delete values[newArrayFilterUrls[i]];
                }

                const arrayImgAux = Object.values(values).splice(10);
                const valuesFinalUrls = arrayImgAux.filter(Boolean);

                if (valuesFinalUrls.length === 0) {
                  valuesFinalUrls.push(
                    "https://cdn.pixabay.com/photo/2013/07/12/12/56/home-146585_640.png"
                  );
                }

                const objectProduct = {
                  name: values.name,
                  typeSelect: values.typeSelect,
                  statusSelect: values.statusSelect,
                  currencySelect: values.currencySelect,
                  price: values.price,
                  available: "si",
                  visibility: "activada",
                  land:
                    values.typeSelect === "departamento" ? "0" : values.land,
                  built: values.typeSelect === "terreno" ? "0" : values.built,
                  bedroom:
                    values.typeSelect === "terreno" ? "0" : values.bedroom,
                  bathroom:
                    values.typeSelect === "terreno" ? "0" : values.bathroom,
                  description: values.description,
                  images: valuesFinalUrls,
                };

                handleCreateProduct(objectProduct);
              }}
            >
              {({
                handleSubmit,
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
              }) => (
                <Form className="form-formik-small" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Nombre inmueble</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese Nombre"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={35}
                    />
                    {touched.name && errors.name && (
                      <Form.Text className="text-muted">
                        {errors.name}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Tipo inmueble</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="typeSelect"
                      value={values.typeSelect}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Seleccione</option>
                      <option value="casa">Casa</option>
                      <option value="departamento">Departamento</option>
                      <option value="terreno">Terreno</option>
                    </Form.Select>
                    {touched.typeSelect && errors.typeSelect && (
                      <Form.Text className="text-muted">
                        {errors.typeSelect}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Alquiler / Venta</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="statusSelect"
                      value={values.statusSelect}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Seleccione</option>
                      <option value="alquiler">Alquiler</option>
                      <option value="venta">Venta</option>
                    </Form.Select>
                    {touched.statusSelect && errors.statusSelect && (
                      <Form.Text className="text-muted">
                        {errors.statusSelect}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Moneda de cobro</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="currencySelect"
                      value={values.currencySelect}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Seleccione</option>
                      <option value="ARS">ARS</option>
                      <option value="U$D">USD</option>
                    </Form.Select>
                    {touched.currencySelect && errors.currencySelect && (
                      <Form.Text className="text-muted">
                        {errors.currencySelect}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Precio</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese Precio"
                      id="price"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={35}
                    />
                    {touched.price && errors.price && (
                      <Form.Text className="text-muted">
                        {errors.price}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Terreno (m2)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el tamaño del area del terreno"
                      id="land"
                      disabled={
                        values.typeSelect === "departamento" ? true : false
                      }
                      value={
                        values.typeSelect === "departamento"
                          ? (values.land = "0")
                          : values.land
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={35}
                    />
                    {touched.land && errors.land && (
                      <Form.Text className="text-muted">
                        {errors.land}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Construido (m2)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el tamaño del area construida"
                      id="built"
                      disabled={values.typeSelect === "terreno" ? true : false}
                      value={
                        values.typeSelect === "terreno"
                          ? (values.built = "0")
                          : values.built
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={35}
                    />
                    {touched.built && errors.built && (
                      <Form.Text className="text-muted">
                        {errors.built}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Cantidad de dormitorios</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese la cantidad de dormitorios"
                      id="bedroom"
                      disabled={values.typeSelect === "terreno" ? true : false}
                      value={
                        values.typeSelect === "terreno"
                          ? (values.bedroom = "0")
                          : values.bedroom
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={35}
                    />
                    {touched.bedroom && errors.bedroom && (
                      <Form.Text className="text-muted">
                        {errors.bedroom}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Cantidad de baños</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese la cantidad de baños"
                      id="bathroom"
                      disabled={
                        values.typeSelect === "terreno" ? true : false
                      }
                      value={
                        values.typeSelect === "terreno"
                          ? (values.bathroom = "0")
                          : values.bathroom
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={35}
                    />
                    {touched.bathroom && errors.bathroom && (
                      <Form.Text className="text-muted">
                        {errors.bathroom}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Descripción del inmueble</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Ingrese la descripción"
                    />
                    {touched.description && errors.description && (
                      <Form.Text className="text-muted">
                        {errors.description}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-between my-2">
                    <Form.Label>URLs de las imagenes</Form.Label>
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={handleAddInput}
                    >
                      Añadir nuevo campo para URL
                    </button>
                  </div>
                  <h6 style={{ color: "red" }}>
                    ALERTA: LOS CAMPOS VACIOS DE URLS NO SE GUARDARAN. SE
                    RECOMIENDA BORRARLOS.
                  </h6>
                  <h6 style={{ color: "green" }}>
                    NOTA: EL PRIMER CAMPO DE URL SERA CONSIDERADO COMO LA IMAGEN
                    PRINCIPAL DEL PRODUCTO A MOSTRAR.
                  </h6>
                  {arrayInput.map((i, index) => (
                    <div key={i} className="d-flex w-100 mb-3">
                      <Form.Group className="w-100">
                        <Form.Control
                          type="text"
                          id={i}
                          value={values[i] || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={"Ingrese la " + i}
                        />
                      </Form.Group>
                      <button
                        type="button"
                        onClick={() => handleDeleteInput(index)}
                        disabled={arrayInput.length === 1 ? true : false}
                        className="btn btn-danger"
                      >
                        X
                      </button>
                    </div>
                  ))}

                  {!loadingButton ? (
                    <button className="btn btn-primary mb-2" type="submit">
                      Cargar Inmueble
                    </button>
                  ) : (
                    <button
                      disabled
                      className="btn btn-primary mb-2 p-2 d-flex align-items-center"
                      type="submit"
                    >
                      <Loader />
                    </button>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProducts;
