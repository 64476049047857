import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/error404.css";

const Error404 = () => {
  useEffect(() => {
    document.title = "Pagina no encontrada";
  }, []);

  return (
    <div>
      <div className="mx-auto" style={{ maxWidth: "800px" }}>
        <p className="p-error-text">Error 404</p>
        <p className="p-error-help">
          La pagina a la que deseas acceder no existe. Intenta regresando al
          inicio.
        </p>
      </div>
      <div className="btn-back-home-error-404 d-flex justify-content-center">
        <Link to="/">Volver al Inicio</Link>
      </div>
    </div>
  );
};

export default Error404;
