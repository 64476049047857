import React, { useState, useEffect, useContext } from "react";
import { Carousel } from "react-bootstrap";
import {
  faCaretRight,
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sliderItems from "../Helpers/sliderItems.json";
import ProductsCrudContext from "../Context/ProductsCRUDContext";
import { Link } from "react-router-dom";
import "../Styles/slide.css";

const Slide = () => {
  const { products } = useContext(ProductsCrudContext);
  const [arraySlider, setArraySlider] = useState(sliderItems?.sliderItems);

  const lastElement = () => {
    const arrayAux = [...sliderItems?.sliderItems];
    const indexFound = arrayAux.findIndex((e) => e.id === 3);

    if (products.length !== 0) {
      const array = [...products];
      const arrayAuxProducts = array.filter((e) => e.available === "si");

      const minNumber = 0;
      const maxNumber = arrayAuxProducts.length - 1;
      const randomPosition = Math.floor(
        Math.random() * (maxNumber - minNumber) + minNumber
      );

      const linkAux = "/product/" + arrayAuxProducts[randomPosition].id;
      if (arrayAux[indexFound].link !== linkAux) {
        arrayAux[indexFound].link = linkAux;
        setArraySlider([...arrayAux]);
      }
    }
  };

  lastElement();

  return (
    <>
      <Carousel
        prevIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />}
        nextIcon={<FontAwesomeIcon icon={faCircleChevronRight} />}
        className="mx-auto"
        style={{ maxWidth: "1340px" }}
      >
        {arraySlider.map((i) => (
          <Carousel.Item
            key={"item-carosel-" + i.id}
            className="carousel-item-custom"
            interval={1000000}
          >
            <img
              className="d-block w-100 fondo-01"
              src={i.img_background}
              alt="slide"
            />
            <div className="container-carousel-item-logo">
              {i.show_logo !== undefined ? (
                i.show_logo ? (
                  <img
                    src="/Images/logo-estudioq.png"
                    className="logo-estudio-slider"
                    alt=""
                  />
                ) : (
                  <a href="http://microcasas.ar/" target="_blank">
                    <img src="/Images/slide_01-logo.png" alt="" />
                  </a>
                )
              ) : (
                <></>
              )}
            </div>
            <div className="container-carousel-item-desc d-flex flex-column">
              <p>{i.description}</p>
              {i.button_desc.length > 0 && (
                <Link to={i.link} className="d-flex align-items-center">
                  {i.button_desc} <FontAwesomeIcon icon={faCaretRight} />{" "}
                </Link>
              )}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};
export default Slide;
