import React, { useEffect, useContext } from "react";
import AuthContext from "../Context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import LoadingPage from "../Components/LoadingPage";

const MyAccount = () => {
  const { user, loading, logout, userInDB } = useContext(AuthContext);
  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  useEffect(() => {
    document.title = "Mi cuenta";
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <h1>
        Mi cuenta{" "}
        <Button
          onClick={() => navigate("edit-profile")}
          className="btn-success"
        >
          Editar
        </Button>{" "}
      </h1>
      <div className="d-flex flex-column">
        <div className="d-flex flex-column my-2">
          <h6>CORREO:</h6>
          <p>{user.email}</p>
        </div>
        <div className="d-flex flex-column my-2">
          <h6>USUARIO:</h6>
          <p>{user.displayName}</p>
        </div>
        <div className="d-flex flex-column my-2">
          <h6>ROL:</h6>
          <p style={{ color: "blue", fontSize: "1.5rem" }}>{rolUserDB}</p>
        </div>
        <div className="d-flex flex-column">
          <h6>Foto:</h6>
          <img
            className="img-fluid"
            style={{ width: "10rem", heigth: "10rem" }}
            src={user.reloadUserInfo.photoUrl || user.photoURL}
            alt=""
          />
        </div>
      </div>
      <Button onClick={handleLogout} className="btn-danger mt-3">
        Logout
      </Button>
    </div>
  );
};

export default MyAccount;
