import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import '../Styles/loader.css';

const FooterContact = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const sendMessage = (nameUser, phoneNumber, email, message) => {
    fetch(
      'https://back-inmobiliaria-estudio-q.onrender.com/addContactMessage',
      {
        method: 'POST',
        crossDomain: true,
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          name: nameUser,
          phoneNumber: phoneNumber,
          email: email,
          message: message,
        }),
      }
    ).then(() => window.location.reload());
  };

  return (
    <footer
      className='mt-auto'
      style={{
        width: '100%',
        marginTop: '1rem',
      }}
    >
      <Container fluid style={{ maxWidth: '1200px' }}>
        <Row className='d-flex columna-img'>
          <Col xl={5} lg={5} sm={5} className='col-social-network'>
            <div className='d-flex flex-column justify-content-center'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <h3 className='text-center'>
                  <strong>Contáctanos</strong>
                </h3>
              </div>

              <div className='d-flex flex-column container-img-footer'>
                <img src='/Images/redes.png' alt='' width={'300vh'} />
                <div className='d-flex justify-content-end contain-logos-sn'>
                  <a
                    href='https://www.facebook.com/arquifranquiroga'
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    href='https://www.instagram.com/arq.estudioq/'
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xl={7}
            lg={7}
            sm={7}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            className='mt-4'
          >
            <Formik
              initialValues={{
                fullName: '',
                email: '',
                phoneNumber: '',
                message: '',
              }}
              validate={(values) => {
                let errors = {};

                if (values.email.trim() === '') {
                  errors.email = 'Requerido';
                } else if (
                  !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                    values.email
                  )
                ) {
                  errors.email = 'Email no valido.';
                }

                if (values.fullName.trim() === '') {
                  errors.fullName = 'Requerido.';
                } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.fullName)) {
                  errors.fullName = 'Nombre/s invalido.';
                } else if (values.fullName.trim().split('').length < 3) {
                  errors.fullName = 'Nombre muy corto (Min 3 letras).';
                }

                if (values.message.trim() === '') {
                  errors.message = 'Requerido';
                }

                if (values.phoneNumber.trim() === '') {
                  errors.phoneNumber = 'Requerido.';
                } else if (values.phoneNumber?.trim().length > 0) {
                  if (
                    !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
                      values.phoneNumber
                    )
                  ) {
                    errors.phoneNumber = 'Teléfono no valido';
                  }
                }

                return errors;
              }}
              onSubmit={(values, { resetForm }) => {
                setLoading(true);
                sendMessage(
                  values.fullName,
                  values.phoneNumber,
                  values.email,
                  values.message
                );
              }}
            >
              {({
                handleSubmit,
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
              }) => (
                <Form
                  className='w-100 form-contact-footer'
                  onSubmit={handleSubmit}
                >
                  <Form.Group>
                    <Form.Control
                      type='text'
                      placeholder='Nombre y Apellido'
                      id='fullName'
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={50}
                    />
                    {touched.fullName && errors.fullName && (
                      <Form.Text className='text-muted'>
                        {errors.fullName}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type='text'
                      placeholder='Teléfono'
                      id='phoneNumber'
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={50}
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <Form.Text className='text-muted'>
                        {errors.phoneNumber}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type='text'
                      placeholder='Email'
                      id='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={50}
                    />
                    {touched.email && errors.email && (
                      <Form.Text className='text-muted'>
                        {errors.email}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      as='textarea'
                      id='message'
                      rows={3}
                      placeholder='Consulta'
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.message && touched.message && (
                      <Form.Text className='text-muted'>
                        {errors.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className='d-flex container-btn-submit-contact'>
                    {loading ? (
                      <button disabled>
                        <Loader />
                      </button>
                    ) : (
                      <button type='submit'>Enviar</button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterContact;
