import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Styles/product.css";
import CardAlquilerPreview from "../Components/CardAlquilerPreview";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsCrudContext from "../Context/ProductsCRUDContext";
import { useParams, useLocation } from "react-router-dom";
import ShareNetworks from "../Components/ShareNetworks";
import LoadingPage from "../Components/LoadingPage";

const Product = () => {
  const { getProductsById, product, products } =
    useContext(ProductsCrudContext);

  const { id } = useParams();
  const { pathname } = useLocation();
  const [paramsId, setParamsId] = useState(id);

  if (paramsId !== id) {
    window.location.reload();
  }

  const [finalStatus, setFinalStatus] = useState("");
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [stsInitial, setStsInitial] = useState(7);
  const [sts1000, setSts1000] = useState(6);
  const [sts767, setSts767] = useState(5);
  const [sts540, setSts540] = useState(4);
  const [sts390, setSts390] = useState(3);

  const changeSliderContent = () => {
    if (product?.images.length === 6) {
      setStsInitial(6);
    } else if (product?.images.length === 5) {
      setStsInitial(5);
      setSts1000(5);
    } else if (product?.images.length === 4) {
      setStsInitial(4);
      setSts1000(4);
      setSts767(4);
    } else if (product?.images.length === 3) {
      setStsInitial(3);
      setSts1000(3);
      setSts767(3);
      setSts540(3);
    } else if (product?.images.length <= 2) {
      setStsInitial(2);
      setSts1000(2);
      setSts767(2);
      setSts540(2);
      setSts390(2);
    }
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div>
      <div
        {...props}
        className={
          "slick-prev prev-slick-arrow d-flex justify-content-center align-items-center" +
          (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
      >
        <FontAwesomeIcon icon={faCircleChevronLeft} />
      </div>
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div>
      <div
        {...props}
        className={
          "slick-next next-slick-arrow d-flex justify-content-center align-items-center" +
          (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
      >
        <FontAwesomeIcon icon={faCircleChevronRight} />
      </div>
    </div>
  );

  useEffect(() => {
    getProductsById(id);
    changeSliderContent();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (product) {
      document.title = product?.name;
    }

    if (product?.available === "si") {
      if (product?.statusSelect === "venta") {
        setFinalStatus("En venta");
      } else {
        setFinalStatus("En alquiler");
      }
    } else {
      if (product?.statusSelect === "venta") {
        setFinalStatus("Vendida");
      } else {
        setFinalStatus("Alquilada");
      }
    }
  }, [product]);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: stsInitial,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: sts1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: sts767,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: sts540,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: sts390,
        },
      },
    ],
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    infinite: product?.images.length > 6,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };

  if (!product) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="title-prop product-title-page d-flex justify-content-center">
        <p>Catálogo de propiedades</p>
      </div>

      <div className="mx-auto w-100" style={{ maxWidth: "1340px" }}>
        <div className="row m-0">
          <div className="col-12 col-md-6">
            <div className="slider-wrapper custom-slider-wrapper">
              <Slider
                {...settingsMain}
                asNavFor={nav2}
                ref={(slider) => setSlider1(slider)}
              >
                {product?.images.map((img_url) => (
                  <div
                    className="slick-slide d-flex justify-content-center"
                    key={img_url}
                  >
                    <img
                      className="slick-slide-image img-first-slider img-fluid img-first-slider-custom"
                      src={`${img_url}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap thumbnail-slider-wrap-display-custom">
              <Slider
                {...settingsThumbs}
                infinite={product?.images.length > 5}
                asNavFor={nav1}
                ref={(slider) => setSlider2(slider)}
              >
                {product?.images.map((img_url) => (
                  <div
                    className="slick-slide slasdad div-slick-img-overflow"
                    key={img_url}
                  >
                    <img className="slick-slide-image" src={`${img_url}`} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex justify-content-center contain-p-product">
              <p className="p-product-type">{product.typeSelect}</p>
              <p className="p-product-status">{finalStatus}</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="product-name-page">{product.name}</p>
              <ShareNetworks shareUrl={window.location.href} />
            </div>
            <div>
              <div className="row row-container-product-detail-page">
                <div className="col-5 p-0">
                  {product.land !== "0" && <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Terreno:</p>
                    <p className="p-size-area">{product.land + "m2"}</p>
                  </div>}
                  {product.built !== "0" && <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Construidos:</p>
                    <p className="p-size-area">{product.built + "m2"}</p>
                  </div>}
                </div>
                <div className="col-6 p-0">
                  {product.bedroom !== "0" && <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Dormitorios:</p>
                    <p className="p-size-area">{product.bedroom}</p>
                  </div>}
                  {product.bathroom !== "0" && <div className="d-flex justify-content-start container-text-area-alquiler">
                    <p>Baños:</p>
                    <p className="p-size-area">{product.bathroom}</p>
                  </div>}
                </div>
              </div>
            </div>
            <div className="container-desc-product-detail">
              <p>{product.description}</p>
            </div>
            <div className="d-flex">
              <div className="row w-100 justify-content-between row-price-and-btn-product-detail">
                <div className="col-6 p-0 card-price-alquiler container-product-detail-price">
                  <p>
                    {product.currencySelect +
                      " " +
                      new Intl.NumberFormat("es-ES").format(
                        Number(product?.price)
                      )}
                  </p>
                </div>
                <div className="col-5 p-0 d-flex justify-content-end align-items-center container-btn-contact-product-detail">
                  <a
                    href={`https://wa.me/3816808535?text=Quisiera%20hacer%20una%20consulta%20sobre%20el%20siguiente%20inmueble:%20${product?.name}`}
                    target="_blank"
                    className="a-style-contactar"
                  >
                    Contactar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="thumbnail-slider-wrap thumbnail-slider-wrap-display-none-custom">
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={(slider) => setSlider2(slider)}
            >
              {product?.images.map((img_url) => (
                <div
                  className="slick-slide div-slick-img-overflow"
                  key={img_url}
                >
                  <img
                    className="slick-slide-image slick-slide-image-custom"
                    src={`${img_url}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="container-products-list">
        <div className="row w-100" style={{ maxWidth: "1200px" }}>
          {products.length === 0 ? (
            <LoadingPage />
          ) : (
            products?.map(
              (card, index) =>
                card.visibility === "activada" && (
                  <CardAlquilerPreview key={index} object={card} />
                )
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
