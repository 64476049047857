import React from "react";
import { Link } from "react-router-dom";

const NavAdmin = () => {
  return (
    <div className="mx-auto container-nav-admin" style={{ maxWidth: "1340px" }}>
      <div className="d-flex justify-content-center container-options-admin">
        <Link to="/show-products">Productos</Link>
        <Link to="/all-users">Usuarios</Link>
        <Link to="/show-projects">Proyectos</Link>
      </div>
    </div>
  );
};

export default NavAdmin;
