import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/nav.css";
import {
  faMagnifyingGlass,
  faRightToBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../Context/AuthContext";

const NavCustom = () => {
  const { user } = useContext(AuthContext);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const handleInputSearch = (value) => {
    if (value.trim() === "") {
      navigate(`/search/null`);
    } else {
      navigate(`/search/${value.trim()}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value.trim() === "") {
        navigate(`/search/null`);
      } else {
        navigate(`/search/${event.target.value.trim()}`);
      }
      navigate(`/search/${event.target.value.trim()}`);
    }
  };

  return (
    <div className="background-color-nav">
      <div className="mx-auto" style={{ maxWidth: "1340px" }}>
        <div className="row m-0">
          <Link
            to="/"
            className="col-8 col-sm-8 p-0 d-flex align-items-center justify-content-start justify-content-sm-center container-nav-logo"
          >
            <img
              className="img-fluid"
              src="https://res.cloudinary.com/dtccrvpzp/image/upload/v1676318946/logo-estudioq-blanco_skcgic.png"
              alt=""
            />
          </Link>
          <div className="col-4 col-sm-4 p-0 d-flex justify-content-end container-input-search">
            <form className="d-flex" onSubmit={onSubmit}>
              <button
                className="p-0"
                type="submit"
                onClick={() => handleInputSearch(searchValue)}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
              <input
                type="search"
                id="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="buscar"
                onKeyDown={handleKeyDown}
              />
            </form>
            {user ? (
              <div className="d-flex justify-content-center align-items-center container-btn-login">
                <Link to="/my-account">
                  <FontAwesomeIcon icon={faUser} />
                </Link>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center container-btn-login">
                <Link to="/login">
                  <FontAwesomeIcon icon={faRightToBracket} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavCustom;
