import React, { useContext, useEffect } from "react";
import CardAlquiler from "../Components/CardAlquiler";
import Slide from "../Components/Slide";
import AboutUs from "../Components/AboutUs";
import OurTeam from "../Components/OurTeam";
import OurProjects from "../Components/OurProjects";
import FooterContact from "../Components/FooterContact";
import ProductsCrudContext from "../Context/ProductsCRUDContext";
import LoadingPage from "../Components/LoadingPage";
import "../Styles/home.css";

const Home = () => {
  const { products, setProduct } = useContext(ProductsCrudContext);

  useEffect(() => {
    document.title = "Estudio Q | Inicio";
    setProduct(null);
  }, []);

  return (
    <div>
      <Slide />
      <AboutUs />
      <OurTeam />

      <OurProjects />
      <div
        className="title-prop d-flex justify-content-center mx-auto"
        style={{ maxWidth: "1340px" }}
      >
        <p>Catálogo de propiedades</p>
      </div>
      <div className="mx-auto w-100 mb-5" style={{ maxWidth: "1300px" }}>
        <div className="row m-0">
          {products.length === 0 ? (
            <LoadingPage />
          ) : (
            products?.map(
              (card, index) =>
                card.visibility === "activada" && (
                  <CardAlquiler key={index} object={card} />
                )
            )
          )}
        </div>
      </div>
      <FooterContact />
    </div>
  );
};

export default Home;
