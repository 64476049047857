import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import UpdateProfileUserContext from "../Context/EditProfileContext";
import LoadingPage from "../Components/LoadingPage";

const EditProfile = () => {
  const { user, loading } = useContext(AuthContext);
  const {
    uploaded,
    progress,
    imagePreview,
    updateProfileUser,
    imagePreviewDetail,
    loadingSaveEditProfile,
  } = useContext(UpdateProfileUserContext);
  const navigate = useNavigate();

  const [image, setImage] = useState();

  const handleEditProfile = async (fullName, file) => {
    await updateProfileUser(fullName, file);
  };

  const handleImagePreview = async (file) => {
    if (file) {
      imagePreviewDetail(file);
    }
  };

  useEffect(() => {
    document.title = "Editar perfil";
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <h1>
        Editar cuenta
        <Button onClick={() => navigate("/my-account")} className="btn-warning">
          Volver
        </Button>
      </h1>
      <div className="mt-3 w-50 d-flex flex-column">
        <Formik
          initialValues={{
            email: "" || user.email,
            fullName: "" || user.displayName,
          }}
          validate={(values) => {
            let errors = {};

            if (values.fullName.trim() === "") {
              errors.fullName = "Requerido";
            } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.fullName)) {
              errors.fullName = "Ingrese nombre valido";
            }

            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            handleEditProfile(values.fullName, image);
          }}
        >
          {({
            handleSubmit,
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Nombre completo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese su nombre completo"
                  id="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={35}
                />
                {touched.fullName && errors.fullName && (
                  <Form.Text className="text-muted">
                    {errors.fullName}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese Email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={35}
                  disabled
                />
                {touched.email && errors.email && (
                  <Form.Text className="text-muted">{errors.email}</Form.Text>
                )}
              </Form.Group>
              <div className="d-flex flex-column">
                <h6>Foto:</h6>
                <img
                  className="img-fluid"
                  style={{ width: "10rem", heigth: "10rem" }}
                  src={imagePreview ? imagePreview : user.photoURL}
                  alt=""
                />
                {uploaded && <h4>Uploaded {progress} %</h4>}
                <Form.Group>
                  <Form.Control
                    type="file"
                    id="photoUrl"
                    accept="image/*"
                    onChange={(e) => {
                      handleImagePreview(e.target.files[0]);
                      setImage(e.target.files[0]);
                    }}
                  />
                </Form.Group>
              </div>
              <Button className="mt-3" type="submit">
                Guardar cambios
              </Button>
              {loadingSaveEditProfile && (
                <h5 style={{ color: "red" }}>Guardando...</h5>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditProfile;
