import React, { useEffect, useContext } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import ModalDeleteProduct from "../Components/ModalDeleteProduct";
import AuthContext from "../Context/AuthContext";
import ProductsCrudContext from "../Context/ProductsCRUDContext";
import LoadingPage from "../Components/LoadingPage";
import "../Styles/crudProducts.css";

const Show = () => {
  const { products, deleteProducts, setProduct } =
    useContext(ProductsCrudContext);
  const { user, loading, userInDB } = useContext(AuthContext);
  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Lista de Productos - Administrador";
    setProduct(null);
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (rolUserDB !== "root" && rolUserDB !== "admin") {
    return <Navigate to="/my-account" />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className="container">
        <h1>Listado de Productos</h1>
        <div className="row m-0">
          <div className="col">
            <div className="d-grid gap-2 div-btn-add-product">
              <Link to={`/create-products`} className="mt-2 mb-2">
                Agregar Nuevo Inmueble
              </Link>
            </div>
            <div style={{ overflowX: "scroll" }}>
              <table className="table table-crud-products table-dark table-hover table-striped-columns">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Tipo</th>
                    <th>Alquiler / Venta</th>
                    <th>Disponible</th>
                    <th>Visibilidad</th>
                    <th>Precio</th>
                    <th>Moneda</th>
                    <th>Terreno (m2)</th>
                    <th>Construido (m2)</th>
                    <th>Dormitorios</th>
                    <th>Baños</th>
                    <th>Editar</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((product) => (
                    <tr key={product.id}>
                      <td>{product.name}</td>
                      <td>{product.typeSelect}</td>
                      <td>{product.statusSelect}</td>
                      <td>{product.available}</td>
                      <td>{product.visibility}</td>
                      <td>{product.price}</td>
                      <td>{product.currencySelect}</td>
                      <td>{product.land === "0" ? "-" : product.land}</td>
                      <td>{product.built}</td>
                      <td>{product.bedroom}</td>
                      <td>{product.bathroom}</td>
                      <td>
                        <button
                          onClick={() => {
                            navigate(`/edit-products/${product.id}`);
                          }}
                          className="btn btn-warning"
                          style={{ width: "maxContent" }}
                        >
                          Editar
                        </button>
                      </td>
                      <td>
                        <ModalDeleteProduct
                          product={product}
                          deleteProducts={deleteProducts}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Show;
