import React from "react";
import { Col } from "react-bootstrap";

const TeamMemberDetail = ({ t, selected }) => {
  return (
    <>
      <Col className="text-center d-flex flex-column justify-content-center">
        <div
          className={`contain-photo-team${
            t.show ? " active-contain-photo" : ""
          }`}
        >
          <img
            src={t.photo}
            alt={"photo_person_" + t.id}
            style={{ width: "40%" }}
            className="logo-faces-our-team"
            onClick={() => selected(t.id)}
          />
        </div>

        <div
          className={`detail-person${t.show ? " active-detail-person" : ""}`}
          style={{ color: "red", fontSize: "1vw" }}
        >
          <p className="nombre">
            {t.rol}
            <strong>{t.name}</strong>
          </p>
          <p className="cargo" style={{ color: "black" }}>
            {t.desc}
          </p>
        </div>
      </Col>
    </>
  );
};

export default TeamMemberDetail;
