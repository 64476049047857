import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import ourTeamArray from "../Helpers/ourTeamArray.json";
import Carousel from "react-bootstrap/Carousel";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import TeamMemberDetail from "./TeamMemberDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/ourTeam.css";

const OurTeam = () => {
  const [arrayTeamQ, setArrayTeamQ] = useState([]);

  const selected = (id) => {
    const arrayAux = arrayTeamQ;
    const indexFound = arrayAux.findIndex((t) => t.id === id);
    const indexFoundShowInTrue = arrayAux.findIndex((t) => t.show === true);

    if (indexFound !== -1) {
      if (indexFoundShowInTrue !== -1) {
        if (indexFound === indexFoundShowInTrue) {
          arrayAux[indexFoundShowInTrue].show = false;
        } else {
          arrayAux[indexFoundShowInTrue].show = false;
          arrayAux[indexFound].show = true;
        }
      } else {
        arrayAux[indexFound].show = true;
      }
    }

    setArrayTeamQ([...arrayAux]);
  };

  useEffect(() => {
    setArrayTeamQ(ourTeamArray?.teamArray);
  }, []);

  return (
    <div className="container-our-team mx-auto" style={{ maxWidth: "1340px" }}>
      <div className="title-prop our-team-title d-flex justify-content-center">
        <p>Nuestro equipo</p>
      </div>
      <Container className="mx-auto container-detail-our-team" fluid>
        <Row className="m-0 row-view-our-team">
          {arrayTeamQ.map((t) => (
            <TeamMemberDetail
              key={"team-member-" + t.id}
              t={t}
              selected={selected}
            />
          ))}
        </Row>
        <Carousel
          prevIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />}
          nextIcon={<FontAwesomeIcon icon={faCircleChevronRight} />}
          className="carousel-our-team mt-3"
        >
          {ourTeamArray?.teamArray.map((t) => (
            <Carousel.Item className="text-center" key={"team-member-" + t.id}>
              <div className="contain-photo-team">
                <img
                  src={t.photo}
                  alt={"photo_person_" + t.id}
                  style={{ width: "40%" }}
                  className="logo-faces-our-team"
                />
              </div>
              <div
                className="d-flex flex-column"
                style={{ color: "red", fontSize: "1vw" }}
              >
                <p className="nombre">
                  {t.rol}
                  <strong>{t.name}</strong>
                </p>
                <p className="cargo" style={{ color: "black" }}>
                  {t.desc}
                </p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default OurTeam;
