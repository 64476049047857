import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Styles/aboutUs.css";

const AboutUs = () => {
  return (
    <div
      className="container-about-us mx-auto"
      style={{
        maxWidth: "1340px",
      }}
    >
      <Container
        fluid
        style={{
          display: "flex",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            className="text-right col-12 col-sm-6"
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <p className="text-center p-about-us mr-2">¿Quiénes somos?</p>
          </Col>
          <Col
            className="text-left col-12 col-sm-6"
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="text-left text-about-us w-100">
              Somos un Estudio de Arquitectura,
              <br /> orientado al desarrollo de proyectos inmobiliarios para
              comercialización.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
