import React, { useState, useEffect, useContext } from "react";
import UsersCRUDContext from "../Context/UsersCRUDContext";
import AuthContext from "../Context/AuthContext";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import LoadingPage from "../Components/LoadingPage";
import Loader from "../Components/Loader";

const EditUserCRUD = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const { user, loading, userInDB } = useContext(AuthContext);
  const [userSelect, setUserSelect] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";

  const handleUpdateUser = (uid, email, fullName, rol) => {
    setLoadingButton(true);
    fetch("https://backend-inmobiliaria-estudio-q.onrender.com/updateUser", {
      method: "PUT",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        uid: uid,
        email: email,
        fullName: fullName,
        rol: rol,
      }),
    })
      .then((res) => {
        res.json();
        navigate(0);
      })
      .catch((error) => console.log("error"));
  };

  useEffect(() => {
    document.title = "Editar Usuario - Administrador";
    fetch(
      `https://backend-inmobiliaria-estudio-q.onrender.com/getUserByUID/${params.id}`
    )
      .then((res) => res.json())
      .then((data) => setUserSelect(data));
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (rolUserDB !== "root" && rolUserDB !== "admin") {
    return <Navigate to="/my-account" />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {userSelect?.user ? (
        <div className="d-flex flex-column align-items-center">
          <h1>Editar Usuario</h1>
          <Formik
            initialValues={{
              fullName: userSelect?.user.fullName || "",
              email: userSelect?.user.email || "",
              rolSelect: userSelect?.user.rol || "",
            }}
            validate={(values) => {
              let errors = {};

              if (values.fullName.trim() === "") {
                errors.fullName = "Requerido";
              } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.fullName)) {
                errors.fullName = "Nombre invalido";
              }

              if (values.email.trim() === "") {
                errors.email = "Requerido";
              } else if (
                !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                  values.email
                )
              ) {
                errors.email = "Dirección de Email invalida";
              }

              if (values.rolSelect === "") {
                errors.rolSelect = "Por favor, seleccione un Rol.";
              }

              return errors;
            }}
            onSubmit={(values, { resetForm }) => {
              handleUpdateUser(
                params.id,
                values.email,
                values.fullName,
                values.rolSelect
              );
            }}
          >
            {({
              handleSubmit,
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form
                className="form-formik-small"
                style={{ width: "30rem" }}
                onSubmit={handleSubmit}
              >
                <Form.Group className="mb-3">
                  <Form.Label>Nombre completo</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={
                      userSelect?.user.rol === "root" && rolUserDB === "admin"
                    }
                    placeholder="Ingrese su nombre completo"
                    id="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={35}
                  />
                  {touched.fullName && errors.fullName && (
                    <Form.Text className="text-muted">
                      {errors.fullName}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese Email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={35}
                    disabled
                  />
                  {touched.email && errors.email && (
                    <Form.Text className="text-muted">{errors.email}</Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rol usuario</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="rolSelect"
                    value={values.rolSelect}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={
                      userSelect?.user.rol === "root" ||
                      rolUserDB !== "root" ||
                      rolUserDB === "admin"
                    }
                  >
                    <option value="">Seleccionar Rol</option>
                    <option value="user">Usuario</option>
                    <option value="admin">Administrador</option>
                    {userSelect?.user.rol === "root" && (
                      <option value="root">Root</option>
                    )}
                  </Form.Select>
                  {userSelect?.user.rol === "root" && rolUserDB === "root" && (
                    <p style={{ color: "red" }}>
                      Eres el usuario ROOT. No puede modificar su rol.
                    </p>
                  )}
                  {touched.rolSelect && errors.rolSelect && (
                    <Form.Text className="text-muted">
                      {errors.rolSelect}
                    </Form.Text>
                  )}
                </Form.Group>

                {!loadingButton ? (
                  <Button variant="primary" type="submit">
                    Guardar cambios
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    className="d-flex align-items-center p-2"
                  >
                    <Loader />
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};

export default EditUserCRUD;
