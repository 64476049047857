import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Col, Row, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import LoadingPage from "../Components/LoadingPage";
import { useNavigate, useParams } from "react-router-dom/dist";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "../Styles/crudProducts.css";

const EditProject2 = () => {
  const [project, setProject] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const getProjectById2 = async (id) => {
    const showProject = await getDoc(doc(db, "projects2", id));
    setProject(showProject.data());
  };

  useEffect(() => {
    getProjectById2(id);
  }, []);

  const updateProject2 = async (id, updatedProject) => {
    const projectDoc = doc(db, "projects2", id);
    await updateDoc(projectDoc, updatedProject);
    navigate(0);
  };

  if (!project) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="text-center mt-3">
        <h1>Agregar Nuevo Proyecto</h1>
      </div>
      <Formik
        initialValues={{
          projectName: project?.projectName,
          projectType: project?.projectType || "",
          land: project?.land || "",
          built: project?.built || "",
          bedroom: project?.bedroom || "",
          bathroom: project?.bathroom || "",
          projectDescription: project?.projectDescription || "",
          image1: project?.arrayImage[0] || "",
          image2: project?.arrayImage[1] || "",
          image3: project?.arrayImage[2] || "",
          image4: project?.arrayImage[3] || "",
          image5: project?.arrayImage[4] || "",
          image6: project?.arrayImage[5] || "",
          image7: project?.arrayImage[6] || "",
          image8: project?.arrayImage[7] || "",
          image9: project?.arrayImage[8] || "",
          image10: project?.arrayImage[9] || "",
          arrayImage: [],
        }}
        validate={(values) => {
          let errors = {};

          if (values.projectName.trim() === "") {
            errors.projectName = "Requerido";
          }

          if (values.projectType.trim() === "") {
            errors.projectType = "Requerido";
          }

          if (values.projectDescription.trim() === "") {
            errors.projectDescription = "Requerido";
          }

          if (values.land.trim() === "") {
            errors.land = "Requerido";
          } else if (!/^[+]?([.]\d+|\d+([.]\d+)?)$/.test(values.land)) {
            errors.land = "Terreno no valido";
          }

          if (values.built.trim() === "") {
            errors.built = "Requerido";
          } else if (!/^[+]?([.]\d+|\d+([.]\d+)?)$/.test(values.built)) {
            errors.built = "Area construida no valida";
          }

          if (values.bedroom.trim() === "") {
            errors.bedroom = "Requerido";
          }

          if (values.bathroom.trim() === "") {
            errors.bathroom = "Requerido";
          } else if (!/^(0|([1-9]\d*))$/.test(values.bathroom)) {
            errors.bathroom = "Cantidad no valida";
          }

          if (values.projectDescription.trim() === "") {
            errors.description = "Requerido";
          }

          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          values.arrayImage.push(
            values.image1,
            values.image2,
            values.image3,
            values.image4,
            values.image5,
            values.image6,
            values.image7,
            values.image8,
            values.image9,
            values.image10
          );

          const auxArray = values.arrayImage.filter(Boolean);

          if (auxArray.length === 0) {
            auxArray.push(
              "https://cdn.pixabay.com/photo/2013/07/12/12/56/home-146585_640.png"
            );
          }

          const upLoadProject = {
            projectName: values.projectName,
            projectType: values.projectType,
            land: values.land,
            built: values.built,
            bedroom: values.bedroom,
            bathroom: values.bathroom,
            projectDescription: values.projectDescription,
            arrayImage: auxArray,
            show: false,
          };

          updateProject2(id, upLoadProject);
          navigate(-1);
        }}
      >
        {({
          handleSubmit,
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
        }) => (
          <div className="container mt-3">
            <Form className="form-formik-small" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Nombre del projecto</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese el nombre del projecto"
                  id="projectName"
                  value={values.projectName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={35}
                />
                {touched.projectName && errors.projectName && (
                  <Form.Text className="text-muted">
                    {errors.projectName}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tipo de proyecto</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese el tipo de projecto"
                  id="projectType"
                  value={values.projectType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={35}
                />
                {touched.projectType && errors.projectType && (
                  <Form.Text className="text-muted">
                    {errors.projectType}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Terreno</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese la superficie"
                  id="land"
                  value={values.land}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={10}
                />
                {touched.land && errors.land && (
                  <Form.Text className="text-muted">{errors.land}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Construido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese la superficie"
                  id="built"
                  value={values.built}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={10}
                />
                {touched.built && errors.built && (
                  <Form.Text className="text-muted">{errors.built}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Dormitorios</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese cantidad de dormitorios"
                  id="bedroom"
                  value={values.bedroom}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={1}
                />
                {touched.bedroom && errors.bedroom && (
                  <Form.Text className="text-muted">{errors.bedroom}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Baños</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese cantidad de baños"
                  id="bathroom"
                  value={values.bathroom}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={1}
                />
                {touched.bathroom && errors.bathroom && (
                  <Form.Text className="text-muted">
                    {errors.bathroom}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese descripción del proyecto"
                  id="projectDescription"
                  value={values.projectDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={2000}
                />
                {touched.projectDescription && errors.projectDescription && (
                  <Form.Text className="text-muted">
                    {errors.projectDescription}
                  </Form.Text>
                )}
              </Form.Group>
              <div className="d-flex justify-content-between my-2">
                <Form.Label>URLs de las imagenes</Form.Label>
              </div>
              <h6 style={{ color: "red" }}>
                ALERTA: LOS CAMPOS VACIOS DE URLS NO SE GUARDARAN.
              </h6>
              <h6 style={{ color: "green" }}>
                NOTA: EL PRIMER CAMPO DE URL SERA CONSIDERADO COMO LA IMAGEN
                PRINCIPAL DEL PROYECTO A MOSTRAR.
              </h6>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>URL imagen 1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image1"
                      value={values.image1 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image1 && errors.image1 && (
                      <Form.Text className="text-muted">
                        {errors.image1}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image2"
                      value={values.image2 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image2 && errors.image2 && (
                      <Form.Text className="text-muted">
                        {errors.image2}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 3</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image3"
                      value={values.image3 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image3 && errors.image3 && (
                      <Form.Text className="text-muted">
                        {errors.image3}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 4</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image4"
                      value={values.image4 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image4 && errors.image4 && (
                      <Form.Text className="text-muted">
                        {errors.image4}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 5</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image5"
                      value={values.image5 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image5 && errors.image5 && (
                      <Form.Text className="text-muted">
                        {errors.image5}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 6</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image6"
                      value={values.image6 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image6 && errors.image6 && (
                      <Form.Text className="text-muted">
                        {errors.image6}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 7</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image7"
                      value={values.image7 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image7 && errors.image7 && (
                      <Form.Text className="text-muted">
                        {errors.image7}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 8</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image8"
                      value={values.image8 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image8 && errors.image8 && (
                      <Form.Text className="text-muted">
                        {errors.image8}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 9</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image9"
                      value={values.image9 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image9 && errors.image9 && (
                      <Form.Text className="text-muted">
                        {errors.image9}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>URL Imagen 10</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese URL de imagen"
                      id="image10"
                      value={values.image10 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={100}
                    />
                    {touched.image10 && errors.image10 && (
                      <Form.Text className="text-muted">
                        {errors.image10}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex justify-content-center">
                <Button className="mb-4 mt-2 w-50" type="submit">
                  Cargar Proyecto
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default EditProject2;
