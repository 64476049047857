import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ModalDeleteProject2 = ({ project2, deleteProject2 }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <button onClick={handleShow} className="btn btn-danger">
        Eliminar
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Eliminar projecto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Esta segura/o que desea eliminar el siguiente projecto?
          <br />
          <br />
          {"Nombre: " + project2.projectName + ""}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success" onClick={handleClose}>
            Cancelar
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              deleteProject2(project2.id);
            }}
          >
            Eliminar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDeleteProject2;
