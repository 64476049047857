import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const CardAlquilerPreview = ({ object }) => {
  const [finalStatus, setFinalStatus] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (object?.available === "si") {
      if (object?.statusSelect === "venta") {
        setFinalStatus("En venta");
      } else {
        setFinalStatus("En alquiler");
      }
    } else {
      if (object?.statusSelect === "venta") {
        setFinalStatus("Vendida");
      } else {
        setFinalStatus("Alquilada");
      }
    }
  }, []);

  return (
    <div className="d-flex col-12 col-md-6 col-lg-4 justify-content-center">
      <div
        className={`d-flex container-card-alquiler flex-column align-items-center ${
          object?.available === "no" ? "container-card-available" : ""
        } `}
      >
        <div className={`d-flex container-img-alquiler `}>
          <img
            className="img-fluid"
            src={object?.images[0]}
            alt="img_alquiler_photo"
          />
        </div>

        <div
          className={`detail-status-alquiler-card ${
            object?.statusSelect === "venta"
              ? "detail-status-en-venta"
              : "detail-status-en-alquiler"
          }`}
        >
          <p>{finalStatus}</p>
        </div>
        <div className="container-details-card-alquiler">
          <p>{object?.name}</p>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-center container-text-area-alquiler">
              <p>Terreno:</p>
              <p className="p-size-area">{object?.land}m2</p>
            </div>
            <div className="d-flex justify-content-center container-text-area-alquiler">
              <p>Construidos:</p>
              <p className="p-size-area">{object?.built}m2</p>
            </div>
            <div className="card-price-alquiler">
              <p>
                {object?.currencySelect +
                  " " +
                  new Intl.NumberFormat("es-ES").format(Number(object?.price))}
              </p>
            </div>
          </div>

          <div className="btn-info-card-alquiler btn-info-status-en-alquiler d-flex justify-content-center">
            <Link
              to={`/product/${object?.id}`}
              style={{
                pointerEvents: object?.available === "si" ? "auto" : "none",
              }}
              onClick={() => {
                navigate(`/product/${object?.id}`);
                navigate(0);
              }}
            >
              Info
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAlquilerPreview;
