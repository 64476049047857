import React from "react";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import Error404 from "../Pages/Error404";
import MyAccount from "../Pages/MyAccount";
import { Route, Routes } from "react-router-dom";
import EditProfile from "../Pages/EditProfile";
import Users from "../Pages/Users";
import UserList from "../Pages/UserList";
import EditUserCRUD from "../Pages/EditUserCRUD";
import EditProducts from "../Pages/EditProducts";
import CreateProducts from "../Pages/CreateProducts";
import ShowProducts from "../Pages/ShowProducts";
import Product from "../Pages/Product";
import UrbanSolutions from "../Pages/UrbanSolutions";
import InhabitTheCity from "../Pages/InhabitTheCity";
import ProjectsCRUD from "../Pages/ProjectsCRUD";
import ShowProjects from "../Pages/ShowProjects";
import EditProject from "../Pages/EditProject";
import SearchResult from "../Pages/SearchResult";
import CreateProject2 from "../Pages/CreateProject2";
import EditProject2 from "../Pages/EditProject2";

const Main = () => {
  return (
    <>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/search/:result" element={<SearchResult />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/crud-users" element={<Users />} />
          <Route path="/all-users" element={<UserList />} />
          <Route path="/all-users/edit/:id" element={<EditUserCRUD />} />
          <Route path="/my-account/edit-profile" element={<EditProfile />} />
          <Route path="/create-products" element={<CreateProducts />} />
          <Route path="/edit-products/:id" element={<EditProducts />} />
          <Route path="/show-products" element={<ShowProducts />} />
          <Route path="/urban-solutions" element={<UrbanSolutions />} />
          <Route path="/inhabit-the-city" element={<InhabitTheCity />} />
          <Route path="/projects-crud" element={<ProjectsCRUD />} />
          <Route path="/show-projects" element={<ShowProjects />} />
          <Route path="/edit-projects/:id" element={<EditProject />} />
          <Route path="/create-project2" element={<CreateProject2 />} />
          <Route path="/edit-projects2/:id" element={<EditProject2 />} />
        </Routes>
      </main>
    </>
  );
};

export default Main;
