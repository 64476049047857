import React, { useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import LoadingPage from "../Components/LoadingPage";
import "../Styles/loginRegister.css";

const Login = () => {
  const { login, loginWithGoogle, loading, user, errorLogin } =
    useContext(AuthContext);

  const handleGoogleSingIn = async () => {
    await loginWithGoogle();
  };

  const handleLogin = async (email, pass) => {
    await login(email, pass);
  };

  useEffect(() => {
    document.title = "Iniciar Sesión";
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (user) {
    return <Navigate to="/my-account" />;
  }

  return (
    <div className="container-login-user">
      <div className="row" style={{ maxWidth: "1340px" }}>
        <div className="col-12 col-lg-4 title-login d-flex justify-content-lg-end">
          <p>Iniciar Sesión</p>
        </div>
        <div className="col-12 col-lg-8">
          {errorLogin && (
            <h5
              style={{
                color: "red",
              }}
            >
              {errorLogin}
            </h5>
          )}
          <Formik
            initialValues={{
              email: "",
              pass: "",
            }}
            validate={(values) => {
              let errors = {};

              if (values.email.trim() === "") {
                errors.email = "Requerido";
              } else if (
                !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                  values.email
                )
              ) {
                errors.email = "Dirección de Email invalida";
              }

              if (values.pass.trim() === "") {
                errors.pass = "Requerido";
              } else if (/\s/.test(values.pass)) {
                errors.pass = "La contraseña no puede tener espacios";
              } else if (
                values.pass.split("").length < 6 ||
                values.pass.split("").length > 14
              ) {
                errors.pass = "Contraseña entre 6 y 14 caracteres";
              }

              return errors;
            }}
            onSubmit={(values, { resetForm }) => {
              handleLogin(values.email, values.pass);
            }}
          >
            {({
              handleSubmit,
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit} className="form-login-custom">
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Ingrese Email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={35}
                  />
                  {touched.email && errors.email && (
                    <Form.Text className="text-muted">{errors.email}</Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    id="pass"
                    value={values.pass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={15}
                    placeholder="Ingrese contraseña"
                  />
                  {touched.pass && errors.pass && (
                    <Form.Text className="text-muted">{errors.pass}</Form.Text>
                  )}
                </Form.Group>
                <div className="d-flex">
                  <button className="btn-login-custom" type="submit">
                    Iniciar Sesión
                  </button>
                  <div className="d-flex align-items-center mx-3 container-login-register-change">
                    <Link className="login-register-change" to="/register">
                      O registrate aquí
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="btn-google-option-login-register">
            <button onClick={handleGoogleSingIn}>
              O inicia sesión con GOOGLE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
