import React, { useState } from "react";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import "../Styles/shareNetworks.css";

const ShareNetworks = ({ shareUrl }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="contain-btn-share">
        <button onClick={handleShow}>
          <FontAwesomeIcon icon={faShareNodes} />
        </button>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header className="p-1" closeButton>
          <h5>Compartir</h5>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-2">
          <div className="d-flex align-items-center justify-content-center container-networks-share">
            <div>
              <WhatsappShareButton url={shareUrl} hashtag="#estudiosQ">
                <WhatsappIcon round />
              </WhatsappShareButton>
            </div>
            <div>
              <FacebookShareButton url={shareUrl} hashtag="#estudiosQ">
                <FacebookIcon round />
              </FacebookShareButton>
            </div>
            <div>
              <TwitterShareButton url={shareUrl} hashtag="#estudiosQ">
                <TwitterIcon round />
              </TwitterShareButton>
            </div>
            <div>
              <TelegramShareButton url={shareUrl} hashtag="#estudiosQ">
                <TelegramIcon round />
              </TelegramShareButton>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ShareNetworks;
