import { useState, useEffect, createContext } from "react";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { query, getDocs, collection } from "firebase/firestore";
import { UpdateProfileProvider } from "./EditProfileContext";

const UsersCrudContext = createContext();

export function UserCrudProvider({ children }) {
  const [errorRegister, setErrorRegister] = useState("");
  const [usersDB, setUsersDB] = useState([]);
  const [usersFirestoreDB, setUsersFirestoreDB] = useState([]);
  const navigate = useNavigate();

  const createUserByAdmin = async (email, pass, fullName, rol, resetForm) => {
    fetch("https://backend-inmobiliaria-estudio-q.onrender.com/createUser", {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        pass: pass,
        fullName: fullName,
        rol: rol,
      }),
    })
      .then((res) => {
        res.json();
        if (res.status === 400) {
          setErrorRegister("Email en uso");
        } else {
          resetForm();
          navigate(0);
        }
      })
      .then((data) => {})
      .catch((error) => console.log("error"));
  };

  const queryFromFirestoreDB = async () => {
    const q = query(collection(db, "users"));
    const arrayAux = [];
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      arrayAux.push({ uid: doc.id, ...doc.data() });
    });
    setUsersFirestoreDB(arrayAux);
  };

  useEffect(() => {
    fetch("https://backend-inmobiliaria-estudio-q.onrender.com/allUsers")
      .then((res) => res.json())
      .then((data) => setUsersDB(data));

    queryFromFirestoreDB();
  }, []);

  useEffect(() => {
    if (errorRegister) {
      setTimeout(() => {
        setErrorRegister("");
      }, 3000);
    }
  }, [errorRegister]);

  return (
    <UsersCrudContext.Provider
      value={{ createUserByAdmin, usersDB, usersFirestoreDB, errorRegister }}
    >
      <UpdateProfileProvider>{children}</UpdateProfileProvider>
    </UsersCrudContext.Provider>
  );
}

export default UsersCrudContext;
