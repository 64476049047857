import { useState, useEffect, createContext } from "react";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

const ProductsCrudContext = createContext();

export function ProductCrudProvider({ children }) {
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsOrder, setProductsOrder] = useState([]);
  const [arrayInput, setArrayInput] = useState([]);
  const [arrayInputObject, setArrayInputObject] = useState({});
  const productsCollection = collection(db, "products");

  const navigate = useNavigate();

  const addToDBproducts = async (object) => {
    await addDoc(productsCollection, object);
    navigate(0);
    navigate("/show-products");
  };

  const getProducts = async () => {
    const data = await getDocs(productsCollection);
    setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const getProductsById = async (id) => {
    const showProduct = await getDoc(doc(db, "products", id));
    updateInputURLs(showProduct.data());
    setProduct(showProduct.data());
  };

  const updateInputURLs = (product) => {
    const arrayAux = product?.images;
    const arrayAuxInput = [];
    const arrayAuxInputObject = {};

    for (let i = 0; i < arrayAux.length; i++) {
      arrayAuxInput.push("url" + (i + 1));
      arrayAuxInputObject["url" + (i + 1)] = arrayAux[i];
    }
    setArrayInput(arrayAuxInput);
    setArrayInputObject(arrayAuxInputObject);
  };

  const handleAddInput = () => {
    let arrayAux = [...arrayInput];
    let lastElement = Number(arrayAux[arrayAux.length - 1].replace("url", ""));
    setArrayInput([...arrayInput, "url" + (lastElement + 1)]);
    setArrayInputObject({
      ...arrayInputObject,
      ["url" + (lastElement + 1)]: "",
    });
  };

  const handleDeleteInput = (index) => {
    let arrayAux = [...arrayInput];
    let arrayAux2 = { ...arrayInputObject };
    arrayAux.splice(index, 1);
    let arrayAuxInput = [];
    let arrayAuxInputObject = {};

    for (let i = 0; i < arrayAux.length; i++) {
      arrayAuxInput.push(arrayAux[i]);
      arrayAuxInputObject[arrayAux[i]] = arrayAux2[arrayAux[i]];
    }

    setArrayInput(arrayAuxInput);
    setArrayInputObject(arrayAuxInputObject);
  };

  const deleteProducts = async (id) => {
    const productDoc = doc(db, "products", id);
    await deleteDoc(productDoc);
    alert("Producto borrado");
    navigate(0);
  };

  const updateProduct = async (id, updatedProduct) => {
    const productDoc = doc(db, "products", id);
    await updateDoc(productDoc, updatedProduct);
    navigate(0);
  };

  const orderByProducts = () => {
    const q = query(productsCollection, orderBy("price", "asc"));
    const array = onSnapshot(q, (snapshot) =>
      setProductsOrder(snapshot.docs.map((doc) => ({ ...doc.data() })))
    );
  };

  useEffect(() => {
    // orderByProducts();
    getProducts();
  }, []);

  return (
    <ProductsCrudContext.Provider
      value={{
        addToDBproducts,
        products,
        getProductsById,
        product,
        arrayInput,
        arrayInputObject,
        handleAddInput,
        deleteProducts,
        handleDeleteInput,
        updateProduct,
        setProduct,
      }}
    >
      {children}
    </ProductsCrudContext.Provider>
  );
}

export default ProductsCrudContext;
