import React from "react";
import { Link } from "react-router-dom";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/ourProjects.css";

const OurProjects = () => {
  return (
    <div className="d-flex fluid justify-content-center container-project">
      <div className="row m-0 w-100">
        <div className="col-md-7 px-0 mx-0">
          <img
            className="img-fluid img-custom"
            src="../Images/nuestrosproyectos-frente.png"
            alt=""
          />
        </div>
        <div className="col-md-5 img-background">
          <div className="d-flex flex-column margin-l h-100">
            <div className="">
              <p className="font-proyectos">Nuestros proyectos</p>
            </div>
            <div className="margin-bot">
              <p className="font-construimos">Construimos</p>
              <p className="font-urbana">La Imagen Urbana</p>

              <div className="btn-info-card-alquiler btn-info-status-en-alquiler d-flex justify-content-start">
                <Link to="/urban-solutions">
                  Info <FontAwesomeIcon icon={faCaretRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProjects;
