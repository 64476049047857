import { useState, createContext } from "react";
import { updateProfile } from "firebase/auth";
import { auth, db, storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ProductCrudProvider } from "./ProductsCRUDContext";

const UpdateProfileUserContext = createContext();

export function UpdateProfileProvider({ children }) {
  const [imagePreview, setImagePreview] = useState("");
  const [loadingSaveEditProfile, setLoadingSaveEditProfile] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [progress, setProgress] = useState();
  const navigate = useNavigate();

  // UPDATE PROFILE

  const updateProfileUser = async (fullName, file) => {
    setLoadingSaveEditProfile(true);
    try {
      await updateDoc(doc(db, "users", auth.currentUser?.uid), {
        fullName: fullName,
      });
      updateProfile(auth.currentUser, {
        displayName: fullName,
      });
      if (file) {
        console.log("ejecuto la carga de img ", file);
        updateImgProfile(file);
      }
      setLoadingSaveEditProfile(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateImgProfile = (file) => {
    const storageRef = ref(
      storage,
      `photoProfileUsers/${auth.currentUser?.uid}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => console.log("error upload img: ", error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log("img current user: ", auth.currentUser);
          console.log("url: ", url);
          try {
            updateProfile(auth.currentUser, { photoURL: url });
            navigate(0);
          } catch (error) {
            console.log(error);
          }
        });
      }
    );
  };

  const imagePreviewDetail = (file) => {
    const storageRef = ref(
      storage,
      `photoProfileUsersPreview/${auth.currentUser?.uid}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
        setUploaded(true);
      },
      (error) => console.log("error upload img: ", error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUploaded(false);
          setImagePreview(url);
        });
      }
    );
  };

  return (
    <UpdateProfileUserContext.Provider
      value={{
        uploaded,
        progress,
        imagePreview,
        updateProfileUser,
        imagePreviewDetail,
        loadingSaveEditProfile,
      }}
    >
      <ProductCrudProvider>{children}</ProductCrudProvider>
    </UpdateProfileUserContext.Provider>
  );
}

export default UpdateProfileUserContext;
