import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ModalDeleteUser = ({ user, handleDeleteUser, disabled }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <button
        onClick={handleShow}
        disabled={disabled || user.rol === "root"}
        className="btn btn-danger"
      >
        Eliminar
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Eliminar producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Esta segura/o que desea eliminar el siguiente usuario?
          <br />
          <br />
          {"Nombre: " + user.fullName + ""}
          <br />
          {"Email: " + user.email + ""}
          <br />
          {"Rol: " + user.rol + ""}
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success" onClick={handleClose}>
            Cancelar
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              handleDeleteUser(user);
            }}
          >
            Eliminar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDeleteUser;
