import { useState, useEffect, createContext } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { UserCrudProvider } from "./UsersCRUDContext";

const RegisterLoginContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [errorLogin, setErrorLogin] = useState("");
  const [errorRegister, setErrorRegister] = useState("");
  const [userInDB, setUserInDB] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // SIGN UP

  const signUp = async (email, pass, fullName) => {
    try {
      const currentUser = await createUserWithEmailAndPassword(
        auth,
        email,
        pass
      ).catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setErrorRegister("Email en uso");
        } else {
          console.log(error);
        }
      });
      await updateProfile(auth.currentUser, {
        displayName: fullName,
        photoURL:
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      });
      await setDoc(doc(db, "users", currentUser.user.uid), {
        fullName: fullName,
        email: email,
        rol: "user",
      });

      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  // LOGIN

  const login = (email, pass) => {
    signInWithEmailAndPassword(auth, email, pass).catch((error) => {
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        setErrorLogin("Email o contraseña incorrectos");
      } else if (error.code === "auth/too-many-requests") {
        setErrorLogin(
          "Intentaste iniciar sesión muchas veces. Cambia la contraseña o intentalo mas tarde."
        );
      }
    });
  };

  const loginWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider)
      .then(async (currentUser) => {
        await setDoc(doc(db, "users", currentUser.user.uid), {
          email: currentUser.user.email,
          fullName: currentUser.user.displayName,
          rol: "user",
        });
        navigate("/my-account");
      })
      .catch((error) => console.log("google error: ", error));
  };

  // LOGOUT

  const logout = () => signOut(auth);

  // USE EFFECT

  useEffect(() => {
    if (errorRegister) {
      setTimeout(() => {
        setErrorRegister("");
      }, 3000);
    }
  }, [errorRegister]);

  useEffect(() => {
    if (errorLogin) {
      setTimeout(() => {
        setErrorLogin("");
      }, 3000);
    }
  }, [errorLogin]);

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      currentUser &&
        setUserInDB(await getDoc(doc(db, "users", currentUser.uid)));
      setLoading(false);
    });
  }, []);

  return (
    <>
      <RegisterLoginContext.Provider
        value={{
          loginWithGoogle,
          user,
          loading,
          userInDB,
          logout,
          signUp,
          errorRegister,
          errorLogin,
          login,
        }}
      >
        <UserCrudProvider>{children}</UserCrudProvider>
      </RegisterLoginContext.Provider>
    </>
  );
}

export default RegisterLoginContext;
