import React, { useContext } from "react";
import ProductsCrudContext from "../Context/ProductsCRUDContext";
import { useParams } from "react-router-dom";
import CardAlquiler from "../Components/CardAlquiler";
import LoadingPage from "../Components/LoadingPage";
import "../Styles/searchResult.css";

const SearchResult = () => {
  const { products } = useContext(ProductsCrudContext);
  const { result } = useParams();
  let arrayResult = [];

  products?.map(
    (r) =>
      r.name.toLowerCase().includes(result.toLowerCase().trim()) &&
      arrayResult.push(r)
  );

  if ((products.length === 0) === true) {
    return <LoadingPage />;
  }

  return (
    <div className="mx-auto" style={{ maxWidth: "1340px" }}>
      <div className="container-your-research d-flex">
        <p>Tu búsqueda: </p>
        <p>{result === "null" ? "" : result}</p>
      </div>
      <div className="row m-0">
        {arrayResult?.length !== 0 ? (
          arrayResult?.map((r) => <CardAlquiler key={r.id} object={r} />)
        ) : (
          <div className="container-result-search-not-found">
            <p>Lo que intentaste buscar no existe.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResult;
