import React from "react";
import "../Styles/footer.css";

const Footer = () => {
  return (
    <div className="d-flex flex-column mt-auto">
      <div className="footer-bottom-custom d-flex justify-content-center align-items-center">
        <a href="/">
          <img
            className="img-fluid"
            src="https://res.cloudinary.com/dtccrvpzp/image/upload/v1676424233/Inmobiliaria/logo-estudioq_jktfbl.png"
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
