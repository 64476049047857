import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../Context/AuthContext";
import UsersCRUDContext from "../Context/UsersCRUDContext";
import { Formik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import LoadingPage from "../Components/LoadingPage";
import Loader from "../Components/Loader";

const Users = () => {
  const { user, loading, userInDB } = useContext(AuthContext);
  const { createUserByAdmin, errorRegister } = useContext(UsersCRUDContext);
  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = useNavigate();

  const handleCreateUserByAdmin = (email, pass, fullName, rol, resetForm) => {
    setLoadingButton(true);
    createUserByAdmin(email, pass, fullName, rol, resetForm);
  };

  useEffect(() => {
    document.title = "Alta de usuario - Administrador";
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (rolUserDB !== "root" && rolUserDB !== "admin") {
    return <Navigate to="/my-account" />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-center">
        <h1>Alta de usuario</h1>
        <div>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={
              <Popover id={`popover-positioned-right`}>
                <Popover.Header as="h3">Usuario creado</Popover.Header>
                <Popover.Body>
                  Usando rol <strong>ROOT</strong> o <strong>ADMIN</strong>, el
                  usuario creado tendra el rol <strong>USER</strong>. Si desea
                  modificar el rol, debera pedirselo al usuario{" "}
                  <strong>ROOT</strong>.
                </Popover.Body>
              </Popover>
            }
          >
            <Button className="mx-2" variant="success">
              Ayuda
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          pass: "",
          checkPass: "",
          rolSelect: "user",
        }}
        validate={(values) => {
          let errors = {};

          if (values.fullName.trim() === "") {
            errors.fullName = "Requerido";
          } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.fullName)) {
            errors.fullName = "Nombre invalido";
          }

          if (values.email.trim() === "") {
            errors.email = "Requerido";
          } else if (
            !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
              values.email
            )
          ) {
            errors.email = "Dirección de Email invalida";
          }

          if (values.pass.trim() === "") {
            errors.pass = "Requerido";
          } else if (/\s/.test(values.pass)) {
            errors.pass = "La contraseña no puede tener espacios";
          } else if (
            values.pass.split("").length < 6 ||
            values.pass.split("").length > 14
          ) {
            errors.pass = "Contraseña entre 6 y 14 caracteres";
          }

          if (values.checkPass.trim() === "") {
            errors.checkPass = "Requerido";
          } else if (values.pass !== values.checkPass) {
            errors.checkPass = "Las contraseñas no coinciden.";
          } else if (/\s/.test(values.checkPass)) {
            errors.checkPass =
              "La confirmación de la contraseña tampoco puede tener espacios.";
          } else if (
            values.checkPass.split("").length < 6 ||
            values.checkPass.split("").length > 14
          ) {
            errors.checkPass =
              "La confirmación de la contraseña debe tener entre 6 y 14 caracteres.";
          }

          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          handleCreateUserByAdmin(
            values.email,
            values.pass,
            values.fullName,
            values.rolSelect,
            resetForm
          );
        }}
      >
        {({
          handleSubmit,
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
        }) => (
          <Form
            className="form-formik-small"
            style={{ width: "30rem" }}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3">
              <Form.Label>Nombre completo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese su nombre completo"
                id="fullName"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={35}
              />
              {touched.fullName && errors.fullName && (
                <Form.Text className="text-muted">{errors.fullName}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese Email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={35}
              />
              {touched.email && errors.email && (
                <Form.Text className="text-muted">{errors.email}</Form.Text>
              )}
              {errorRegister && (
                <Form.Text className="text-muted">{errorRegister}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                id="pass"
                value={values.pass}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={15}
              />
              {touched.pass && errors.pass && (
                <Form.Text className="text-muted">{errors.pass}</Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirmar contraseña</Form.Label>
              <Form.Control
                type="password"
                id="checkPass"
                value={values.checkPass}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={15}
              />
              {touched.checkPass && errors.checkPass && (
                <Form.Text className="text-muted">{errors.checkPass}</Form.Text>
              )}
            </Form.Group>
            {!loadingButton ? (
              <Button variant="primary" type="submit">
                Dar de alta
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                className="p-2 d-flex align-items-center"
              >
                <Loader />
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Users;
