import React, { useState, useEffect, useContext } from "react";
import { Navigate, Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDocs, deleteDoc, collection } from "firebase/firestore";
import "../Styles/crudProducts.css";
import AuthContext from "../Context/AuthContext";
import ModalDeleteProject from "../Components/ModalDeleteProject";
import ModalDeleteProject2 from "../Components/ModalDeleteProject2";
import LoadingPage from "../Components/LoadingPage";

const ShowProjects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState();
  const [projects2, setprojects2] = useState();
  const { user, loading, userInDB } = useContext(AuthContext);
  const userDB = userInDB._document?.data.value.mapValue.fields;
  const rolUserDB = userDB?.rol?.stringValue || "";

  const getProjects = async () => {
    const data = await getDocs(collection(db, "projects"));
    setProjects(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const getProjects2 = async () => {
    const data = await getDocs(collection(db, "projects2"));
    setprojects2(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getProjects();
    getProjects2();
  }, []);

  const deleteProject = async (id) => {
    const projectDoc = doc(db, "projects", id);
    await deleteDoc(projectDoc);
    navigate(0);
  };

  const deleteProject2 = async (id) => {
    const projectDoc = doc(db, "projects2", id);
    await deleteDoc(projectDoc);
    navigate(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (rolUserDB !== "root" && rolUserDB !== "admin") {
    return <Navigate to="/my-account" />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <h1 className="text-center">Soluciones Urbanas</h1>
      <div className="row m-0">
        <div className="col-12">
          <div className="d-grid gap-2 div-btn-add-product">
            <Link
              style={{
                pointerEvents: projects?.length > 0 ? "none" : "auto",
                backgroundColor: projects?.length > 0 ? "grey" : "auto",
              }}
              to={`/projects-crud`}
              className="mt-2 mb-2"
            >
              Agregar Nuevo Proyecto
            </Link>
          </div>
          <div style={{ overflowX: "scroll" }}>
            <table className="table table-crud-products table-dark table-hover table-striped-columns">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Terreno (m2)</th>
                  <th>Construido (m2)</th>
                  <th>Dormitorios</th>
                  <th>Baños</th>
                  <th>Editar</th>
                  <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {projects &&
                  projects?.map((project) => (
                    <tr key={project.id}>
                      <td>{project.projectName}</td>
                      <td>{project.projectType}</td>
                      <td>{project.land}</td>
                      <td>{project.built}</td>
                      <td>{project.bedroom}</td>
                      <td>{project.bathroom}</td>
                      <td>
                        <button
                          onClick={() => {
                            navigate(`/edit-projects/${project.id}`);
                          }}
                          className="btn btn-warning"
                          style={{ width: "maxContent" }}
                        >
                          Editar
                        </button>
                      </td>
                      <td>
                        <ModalDeleteProject
                          project={project}
                          deleteProject={deleteProject}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-12">
          <h1 className="text-center">Habitar la ciudad</h1>
          <div className="d-grid gap-2 div-btn-add-product">
            <Link
              style={{
                pointerEvents: projects?.length > 0 ? "none" : "auto",
                backgroundColor: projects?.length > 0 ? "grey" : "auto",
              }}
              to={`/create-project2`}
              className="mt-2 mb-2"
            >
              Agregar Nuevo Proyecto
            </Link>
            <div style={{ overflowX: "scroll" }}>
              <table className="table table-crud-products table-dark table-hover table-striped-columns">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Tipo</th>
                    <th>Terreno (m2)</th>
                    <th>Construido (m2)</th>
                    <th>Dormitorios</th>
                    <th>Baños</th>
                    <th>Editar</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {projects2 &&
                    projects2?.map((project) => (
                      <tr key={project.id}>
                        <td>{project.projectName}</td>
                        <td>{project.projectType}</td>
                        <td>{project.land}</td>
                        <td>{project.built}</td>
                        <td>{project.bedroom}</td>
                        <td>{project.bathroom}</td>
                        <td>
                          <button
                            onClick={() => {
                              navigate(`/edit-projects2/${project.id}`);
                            }}
                            className="btn btn-warning"
                            style={{ width: "maxContent" }}
                          >
                            Editar
                          </button>
                        </td>
                        <td>
                          <ModalDeleteProject2
                            project2={project}
                            deleteProject2={deleteProject2}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowProjects;
